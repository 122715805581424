<template>
  <div class="dropdown mt-2">
    <div class="card filter-menu-dropdown">
      <header class="card-header has-background-white">
        <p class="card-header-title has-text-info">
          <span class="icon">
            <i class="fad" :class="filter.icon" />
          </span>
          <span>{{ filter.label }}</span>
        </p>
        <a
          class="card-header-icon has-text-info"
          aria-label="close"
          @click="$emit('close')"
        >
          Close
        </a>
      </header>
      <hr class="hr is-marginless" />
      <component
        v-if="hasData"
        :is="filter.component"
        :data="filterOptions[filter.key]"
        :filter="filter"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'StockviewFilterDropdown',
  components: {
    Checkbox: () => import('./filters/StockviewCheckboxFilter'),
    Slider: () => import('./filters/StockviewSliderFilter'),
    Registration: () => import('./filters/StockviewRegistrationFilter'),
    PricePosition: () => import('./filters/StockviewPricePositionFilter')
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data: () => ({ filters: [] }),
  computed: {
    ...mapGetters('stockview/listings', ['filterOptions']),
    hasData() {
      const { filterOptions, filter } = this
      return filterOptions[filter.key]
    }
  }
}
</script>

<style lang="sass" scoped>
.dropdown
  position: absolute
  left: 50px
  z-index: 2
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
  @media screen and (max-width: 768px)
    left: 0
    position: relative
    width: 100%

.filter-menu-dropdown
  min-width: 24rem
  max-width: 48rem
  @media screen and (max-width: 768px)
    min-width: 100%
</style>
